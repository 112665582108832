import { gsap } from 'gsap';

let carousels = [];
let carouselsRunning = false;

const setFullHeightCssVariable = () => {
    let vh = window.innerHeight * 0.01; // Finds 1% of the viewport height
    document.documentElement.style.setProperty('--vh', `${vh}px`); // Adds the css variable, --vh, to the document element
};

const hideButtons = () => {
    const buttons = document.querySelectorAll(
        '.comp__button, .global-navigation__backlink, .global-header__logo, .global-footer__button'
    );

    if (buttons) {
        buttons.forEach((button) => {
            button.classList.add('hide');
        });
    }
};

const showButtons = () => {
    const buttons = document.querySelectorAll(
        '.comp__button.hide, .global-navigation__backlink.hide, .global-header__logo.hide, .global-footer__button.hide'
    );

    if (buttons) {
        buttons.forEach((button) => {
            button.classList.remove('hide');
        });
    }
};

const getActiveColors = () => {
    return document.querySelectorAll(
        ".color-controlls input[type='checkbox']:checked"
    );
};

const resetColorClass = () => {
    const el_body = document.querySelector('body');
    const currentColorClass = el_body.className.replace('/\bcolor--.*?\b/', '');
    const colorClass = 'color--cyan-magenta-yellow';

    el_body.classList.add(colorClass);
    el_body.classList.remove(currentColorClass);
};

const toggleCheckbox = () => {
    if (getActiveColors().length < 2) {
        const el_checkbox = document.querySelector(
            '.color-controlls input:checked'
        );
        el_checkbox.disabled = true;
    } else {
        const disabledCheckbox = document.querySelector(
            '.color-controlls input:disabled'
        );
        if (disabledCheckbox) {
            disabledCheckbox.disabled = false;
        }
    }
};

const setColorClass = () => {
    const el_checkedBoxes = getActiveColors();
    const el_body = document.querySelector('body');
    const currentColorClass = el_body.className.replace('/\bcolor--.*?\b/', '');

    toggleCheckbox();

    if (el_checkedBoxes.length) {
        let colorClass = 'color-';

        el_checkedBoxes.forEach((checkbox) => {
            const color = checkbox.id;
            colorClass += '-' + color;
        });

        el_body.classList.add(colorClass);
        el_body.classList.remove(currentColorClass);
    }
};

const setCheckStatus = (event) => {
    let el_svg = event.target.parentElement.querySelector('svg');

    if (event.target.checked) {
        el_svg.classList.add('checked');
    } else {
        el_svg.classList.remove('checked');
    }

    setColorClass();
};

const nextImage = (gallery) => {
    const activeClass = 'comp-figures__figure--active';
    const currentImage = gallery.querySelector('.comp-figures__figure--active');
    let nextImage = currentImage.nextElementSibling
        ? currentImage.nextElementSibling
        : gallery.querySelector('.comp-figures__figure');

    currentImage.classList.remove(activeClass);
    nextImage.classList.add(activeClass);
};

const startCarousel = (element) => {
    const speed = parseInt(element.dataset.speed);

    return setInterval(() => {
        nextImage(element);
    }, speed);
};

const startAllCarousels = () => {
    if (!carouselsRunning) {
        const el_carouselControlls =
            document.querySelector('.carouselControlls');
        const el_carousels = document.querySelectorAll(
            '.comp__figures--animated'
        );

        el_carouselControlls.classList.add('running');

        el_carousels.forEach((el_carousel) => {
            carousels.push(startCarousel(el_carousel));
        });

        carouselsRunning = true;
    }
};

const nextImageAll = () => {
    const el_carousels = document.querySelectorAll('.comp__figures--animated');
    el_carousels.forEach((carousel) => {
        nextImage(carousel);
    });
};

const stopCarousel = (carousel) => {
    clearInterval(carousel);
};

const stopAllCarousels = () => {
    if (carouselsRunning) {
        const el_carouselControlls =
            document.querySelector('.carouselControlls');

        el_carouselControlls.classList.remove('running');

        carousels.forEach((carousel) => {
            stopCarousel(carousel);
            // Remove carousel from carousels array!!!
        });

        carouselsRunning = false;
    }
};

// Returns a promise

function loadSrc(img, src) {
    const tmp = new Image();
    tmp.src = src;

    return new Promise((resolve, reject) => {
        tmp.onload = () => {
            img.src = tmp.src;
            resolve(img);
        };

        tmp.onerror = () => reject();
    });
}

function loadSrcset(img, srcset) {
    const tmp = new Image();
    tmp.srcset = srcset;

    return new Promise((resolve, reject) => {
        tmp.onload = () => {
            img.srcset = tmp.srcset;
            resolve(img);
        };

        tmp.onerror = () => reject();
    });
}

function loadMedia(media) {
    let promise = null;

    switch (media.tagName) {
        case 'IMG':
            if (media.dataset.src) {
                promise = loadSrc(media, media.dataset.src);
            }

            if (media.dataset.srcset) {
                promise = loadSrcset(media, media.dataset.srcset);
            }

            break;
    }

    // Remove attributes from image
    promise.then((element) => {
        delete element.dataset.lazy;
        delete element.dataset.src;
        delete element.dataset.srcset;
    });

    // Return promise (Used to unobserve)
    return promise;
}

const preloadmedia = (media) => {
    let promises = [];

    media.forEach((image) => {
        const mediaPromise = loadMedia(image);
        promises.push(mediaPromise);
    });

    return promises;
};

const showOnLoad = () => {
    const el_targets = document.querySelectorAll('.animation--show-on-load');

    gsap.to(el_targets, {
        opacity: 1,
        duration: 1,
    });
};

// Add Eventlisteners to Checkbox elements

document
    .querySelectorAll(".color-controlls input[type='checkbox']")
    .forEach((checkbox) => {
        checkbox.addEventListener('click', (event) => setCheckStatus(event));
    });

// Add Eventlisteners to A elements

document.querySelectorAll('a').forEach((link) => {
    link.addEventListener('click', function (event) {
        event.preventDefault();

        let href = this.href; //Save destination

        // If mailto, tel or #
        if (
            href.search(/mailto/i) > -1 ||
            href.search(/tel/i) > -1 ||
            href.search(/#/i) > -1
        ) {
            window.location.href = href;
        } else {
            //   document.getElementById("content").classList.add("fadeout"); //Hide body
            const el_targets = document.querySelectorAll(
                '.animation--hide-on-load'
            );

            gsap.to(el_targets, {
                opacity: 0,
                duration: 1,
                onComplete: () => {
                    window.location.href = href; //Forward
                },
            });

            resetColorClass();
        }
    });
});

// el_carouselControlls event listeners

const el_carouselControlls__play = document.querySelector(
    '.carouselControlls__play'
);
const el_carouselControlls__pause = document.querySelector(
    '.carouselControlls__pause'
);
const el_carouselControlls__next = document.querySelector(
    '.carouselControlls__next'
);

if (
    el_carouselControlls__play &&
    el_carouselControlls__pause &&
    el_carouselControlls__next
) {
    el_carouselControlls__play.addEventListener('click', (event) => {
        if (!carouselsRunning) {
            startAllCarousels();
        }
    });

    el_carouselControlls__pause.addEventListener('click', (event) => {
        if (carouselsRunning) {
            stopAllCarousels();
        }
    });

    el_carouselControlls__next.addEventListener('click', nextImageAll);
}

// Gallery event listeners

const galleries = document.querySelectorAll('.comp__gallery');

if (galleries.length) {
    galleries.forEach((gallery) => {
        gallery.addEventListener('touchstart', () => {
            gallery.classList.add('touch');
        });

        gallery.addEventListener('touchend', () => {
            gallery.classList.remove('touch');
        });
    });
}

// Update css variable, --vh, on resize
window.addEventListener('resize', setFullHeightCssVariable);

// .comp__button hide buttons
const el_hideTriggers = document.querySelectorAll(
    '.comp__button, .global-footer__button'
);

if (el_hideTriggers) {
    el_hideTriggers.forEach((trigger) => {
        trigger.addEventListener('click', () => {
            hideButtons();
        });
    });
}

// .comp__button show buttons
const el_button__close = document.querySelector('.button__close');
if (el_button__close) {
    el_button__close.addEventListener('click', () => {
        showButtons();
    });
}

// Preload images
const el_toPreload = document.querySelectorAll(
    '.comp__figures img, .comp-teaser img'
);

Promise.all(preloadmedia(el_toPreload)).then(() => {
    const el_carousel = document.querySelector('.comp__figures--animated');

    if (el_carousel) {
        startAllCarousels();
    }

    showOnLoad();
});

setFullHeightCssVariable();
